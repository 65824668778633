<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="18" :class="className" fill="#3CACC5" />
    <path
      d="M24.0181 20.7235L21.9014 20.4818C21.3931 20.4235 20.8931 20.5985 20.5347 20.9568L19.0014 22.4901C16.6431 21.2901 14.7097 19.3651 13.5097 16.9985L15.0514 15.4568C15.4097 15.0985 15.5847 14.5985 15.5264 14.0901L15.2847 11.9901C15.1847 11.1485 14.4764 10.5151 13.6264 10.5151H12.1847C11.2431 10.5151 10.4597 11.2985 10.5181 12.2401C10.9597 19.3568 16.6514 25.0401 23.7597 25.4818C24.7014 25.5401 25.4847 24.7568 25.4847 23.8151V22.3735C25.4931 21.5318 24.8597 20.8235 24.0181 20.7235Z"
      fill="white"
    />
  </svg>
</template>
<script setup lang="ts">
import { computed } from "vue";
type Props = {
  expired?: boolean;
};
const props = defineProps<Props>();
const className = computed(() => {
  return props.expired ? "Icon__expired" : "Icon__active";
});
</script>
<style scoped lang="scss">
.Icon {
  &__active {
    fill: $color-tertiary;
  }
  &__expired {
    fill: $color-neutral-7;
  }
}
</style>
